.content {
  display: flex;
  align-items: center;
  height: 19px;
  position: relative;
  .progress {
    width: 155px;
    border-radius: 5px;
    background: #ededed;
    height: 10px;
    flex: 1;
    
    .timeline {
      border-radius: 5px;
      height: 100%;
    }
  }
  .title {
    width: 75px;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0px;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
  }
}
