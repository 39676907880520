.filter {
  display: flex;
  flex-direction: column;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  border-bottom: 1px solid var(--neutral-colors-neutral-smoke, #ededed);

  .filter-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 78px;
    border-bottom: 1px solid var(--neutral-colors-neutral-smoke, #ededed);
    background: #fff;
    padding: 12px;
    white-space: nowrap;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  .display-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    :global .ant-btn {
      height: 36px;
      width: unset;
      border-radius: 4px;
      font-weight: 400;
      &.active {
        background: var(--primary-colors-brand-brand-primary-yellow, #f0b71c);
      }
      &.de-active {
        background: var(--neutral-colors-neutral-smoke, #ededed);
        color: var(--text-colors-text-color-input-default-placeholder, #a5a5a5);
      }
    }
  }
  .target-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    :global .ant-radio-inner {
      transform: scale(1.25);
    }
    :global .ant-radio-wrapper{
      color: black;
    }
  }
  .filter-group {
    display: flex;
    flex-direction: column;
    padding: 12px;
    background: #fff;
    gap: 12px;
    :global .ant-btn {
      border-radius: 6px;
      background: var(--primary-colors-brand-brand-primary-dark-green, #17345b);
      width: unset;
      color: white;
      font-weight: 400;
      font-size: 16px;
    }
    .button-close {
      width: 105px;
    }
    .filter-section {
      display: flex;
      flex-wrap: wrap;
      // align-items: flex-end;
      flex-direction: row;
      gap: 12px;
      .filter-last {
        display: flex;
        flex-direction: row;
        gap: 12px;
      }
      .filter-field {
        display: flex;
        min-width: 250px;
        width: calc(25% - 12px);
        margin-bottom: 0;
        flex-grow: 1;
        :global .ant-select {
          width: 100%;
        }
        :global .ant-form-item-label {
          height: 34px;
        }
      }
      .button-filter {
        align-self: start;
        display: flex;
        gap: 12px;
        margin-bottom: 0;
        margin-top: 34px;
        :global .button-filter.ant-btn {
          background: var(--primary-colors-brand-brand-primary-yellow, #f0b71c);
          color: black;
          width: 195px;
          border-radius: 6px;
          &:disabled {
            border-radius: 6px;
            background: var(--neutral-colors-neutral-light-gray, #d8d8d8);
            color: var(--text-colors-text-body-mute, #a5a5a5);
          }
        }
        :global .ant-form-item {
          margin-bottom: 0;
        }
        :global .ant-form-item-label {
          height: 34px;
        }
      }
    }
  }
  :global .kpi-wrapper.custom-tree .ant-select-tree-node-selected {
    font-weight: bold;
    background: #fbeac0 !important;
    line-height: 24px;
  }
  :global .ant-form-vertical .ant-form-item-row {
    flex-direction: column;
    width: 100%;
  }
  :global .ant-form-item-label > label::after {
    content: "" !important;
  }
  :global .ant-select-selection-item{
    color: black;
  }
  
}
