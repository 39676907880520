@use "../../../../assets/scss/font";

.container {
  min-height: 300px;
  .table {
    font-family: var(--font-family-regular);
    table-layout: fixed;
    width: 100%;
    tr {
      th {
        @include font.bolder;
      }
    }

    padding: 0px 4px 0px 0px;
    border-spacing: 0;
    .hr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.107);
    }
    .trLayout:nth-child(even) {
      background-color: #f2f2f2;
    }
    .trHover {
      transition: 150ms ease-in;
    }
    .trHover:hover {
      background-color: #ededed;
      cursor: pointer;
    }
    .tr {
      font-size: 14px;
      .td {
        padding: 8px;
      }
      .left {
        text-align: left;
      }
      .center {
        text-align: center;
      }
    }
    tbody {
      .tr {
        .td {
          padding: 8px;
        }
        .left {
          text-align: left;
        }
        .center {
          text-align: center;
        }
      }
    }
  }
}
.center {
  text-align: center;
}

.container::-webkit-scrollbar {
  z-index: 1000000000;
  height: 6px;
  background-color: #f3f3f3;
}

.container::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 2px;
}

.container::-webkit-scrollbar {
  height: 4px;
}

.textNameParent {
  margin-top: 4px;
  font-size: 12px;
  color: #7a7a7a;
}

.textNameChilldren {
  @include font.bold;
}

.loading-more {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.titleMyMission {
  font-weight: bold;
  font-size: 16px;
}

.name-container {
  word-break: break-word;
  white-space: pre-wrap;
}

.loading-more-inner {
  display: flex;
  justify-content: center;
  width: 100% !important;
  span {
    color: #a5a5a5;
    font-weight: 400;
  }
}
