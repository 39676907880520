.employee {
  width: 170px;
  height: 91px;
  display: flex;
  align-items: center;
  .position {
    color: var(--text-colors-text-default-body, #7a7a7a);
    padding: 4px 10px;
    font-size: 14px;
    font-weight: 400;
    border-top: 1px solid var(--neutral-colors-neutral-smoke, #ededed);
    border-bottom: 1px solid var(--neutral-colors-neutral-smoke, #ededed);
    background: var(--neutral-colors-neutral-light, #fafafb);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .detail {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .context {
      width: calc(100% - 20px);
      text-align: left;
      .name {
        text-align: left;
        font-size: 16px;
        color: #7a7a7a;
        line-height: 22px;
        font-weight: 400;
        display: -webkit-box;
        -webkit-box-orient: vertical;  max-width: 100%;
        -webkit-line-clamp: 2;
        overflow: hidden;
        &.bold {
          color: var(--text-colors-text-heading-dark, #000);
          font-weight: 700;
        }
      }
      .email {
        color: var(--text-colors-text-color-input-default-placeholder, #a5a5a5);
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .action {
    outline: none;
    border: none;
    padding: 0;
    width: 16px;
    height: 16px;
  }
}
