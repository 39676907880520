.performance-management {
  height: 100%;
  :global .ant-table-thead > tr > th {
    padding: 0 !important;
  }
  :global .ant-table-thead > tr > th::before {
    content: none !important;
  }
  &.multiple {
    :global .ant-table-row {
      &.summary {
        position: sticky;
        top: 91px;
        z-index: 3;
      }
    }
  }
  :global .ant-table-row {
    &.summary {
      color: white;
      .ant-table-cell {
        background: var(
          --primary-colors-brand-brand-primary-dark-green,
          #17345b
        );
      }
      background: var(--primary-colors-brand-brand-primary-dark-green, #17345b);
    }
    &.category {
      .ant-table-cell {
        background: var(--label-colors-label-yellow, #fff9e8) !important;
      }

      font-weight: bold;
    }

    &.item {
      > td:first-child {
        border-right: 1px solid var(--neutral-colors-neutral-smoke, #ededed);
      }
    }
  }

  &.single {
    :global .ant-table-thead {
      position: sticky;
      top: 0;
      z-index: 6;
    }
  }
  &.multiple {
    :global .ant-table-thead {
      position: sticky;
      top: 0;
      z-index: 5;
    }
    :global .ant-table-thead .ant-table-cell {
      vertical-align: top;
      & > .title-property {
        height: 90px;
        border-right: 1px solid var(--neutral-colors-neutral-smoke, #ededed);
        position: relative;

        .line {
          border-bottom: 1px solid var(--neutral-colors-neutral-smoke, #ededed);
          border-top: 1px solid var(--neutral-colors-neutral-smoke, #ededed);
          position: absolute;
          height: 32px;
          width: 100%;
        }
      }
    }
    :global .ant-table-tbody .ant-table-cell {
      vertical-align: middle;
    }
  }
  &.single {
    :global .ant-table-row {
      &.summary {
        color: white;
        position: sticky;
        top: 50px;
        z-index: 4;
        .ant-table-cell {
          background: var(
            --primary-colors-brand-brand-primary-dark-green,
            #17345b
          );
        }
      }
    }
    :global .ant-table-cell {
      & > .title-property {
        height: unset;
        // border-right: 1px solid var(--neutral-colors-neutral-smoke, #ededed);
        position: relative;
        .line {
          border-bottom: 1px solid var(--neutral-colors-neutral-smoke, #ededed);
          border-top: 1px solid var(--neutral-colors-neutral-smoke, #ededed);
          position: absolute;
          height: 32px;
          width: 100%;
        }
      }
    }
  }

  :global .ant-table-content {
    max-height: calc(100vh - 372px);
    overflow: auto !important;
  }
  .month {
    height: 50px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .approve {
    align-self: flex-end;
    justify-content: center;
    display: flex;
    gap: 12px;
    padding: 15px;
    :global .ant-btn {
      background: var(
        --primary-colors-brand-brand-primary-yellow,
        #f0b71c
      ) !important;
      color: black !important;
      width: 180px;
      height: 45px;
      border-radius: 6px;
      &:disabled {
        color: var(--text-colors-text-body-mute, #a5a5a5) !important;
        background: var(
          --neutral-colors-neutral-light-gray,
          #d8d8d8
        ) !important;
      }
    }
  }
  :global .ant-table-row {
    &.status {
      .ant-table-cell:first-child {
        font-size: 16px;
      }
    }
  }
  :global .ant-table-row:not(.summary):not(.total) {
    :global .ant-table-cell-fix-left {
      border-right: 1px solid #ededed;
    }
  }

  :global .ant-table-row {
    :global .ant-table-cell-fix-left {
      padding: 0 10px;
    }
  }
  &.multiple:not(.table-type-workspace) {
    :global .ant-table-thead tr {
      .ant-table-cell:first-child::after,
      .ant-table-cell:last-child::after {
        content: "";
        display: flex;
        width: 100%;
        height: 32px;
        position: absolute;
        border-top: 1px solid var(--neutral-colors-neutral-smoke, #ededed);
        border-bottom: 1px solid var(--neutral-colors-neutral-smoke, #ededed);
        background: var(--neutral-colors-neutral-light, #fafafb);
        left: 0;
        right: unset;
        bottom: unset;
        top: 0;
        transform: unset;
      }
    }
  }

  :global .ant-table-tbody > tr > td {
    padding: 4px 10px;
    vertical-align: middle;
  }
  :global{
    .ant-table-placeholder td{
      padding: 0 !important;
      .ant-table-expanded-row-fixed{
        padding: 0;
        margin: 0;
      }
    }
  }
  :global .ant-table .ant-table-row.status{
    position: sticky;
    bottom: 0;
    z-index: 5;
    background: white;
  }
}
