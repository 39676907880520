.container {
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 6px;
  height: 46px;
  position: relative;
  padding-right: 50px;
}
.notarrow svg path {
  fill: rgb(122, 122, 122);
}

.arrow,
.notarrow {
  width: 50px;
  height: 46px;
  position: absolute;
  right: 0px;
  top: 0px;
  animation-duration: 0.2s;
  // animation-fill-mode: forwards;
  // z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.arrow {
  animation-name: openArrowSelectBox;
}

.notarrow {
  transform: rotate(0deg);
  animation-name: closeArrowSelectBox;
}
.notarrow svg,
.arrow svg {
  margin: auto !important;
}

@keyframes openArrowSelectBox {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes closeArrowSelectBox {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.box {
  min-width: 100%;
  position: absolute;
  z-index: 10000;
  max-height: 300px;
  min-height: 60px;
  background-color: white;
  box-shadow: 0px 0px 12px 1px rgba(128, 128, 128, 0.408);
  border-radius: 7px;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-name: openBoxSelectBox;
  padding: 5px 0px;
  // opacity: 0;
  overflow-y: auto;
  .nodata {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: grey;
  }
}

@keyframes openBoxSelectBox {
  from {
    height: 0%;
    opacity: 0;
  }

  to {
    height: 100%;
    opacity: 1;
  }
}

.left {
  left: 0px;
}

.right {
  right: 0px;
}

.top {
  top: 47px;
}

.bottom {
  bottom: 47px;
}

.item {
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
}

.item:hover {
  background-color: #f5eace;
  //   border-radius: 4px;
}
.checked {
  background-color: #f0b71c;
  //   border-radius: 4px;
}
.checked:hover {
  background-color: #f5eace;
}

.view {
  width: 100%;
  height: 100%;
  padding: 0px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 46px;
  font-size: 16px;
}
.view span {
  color: #a5a5a5;
  cursor: pointer;
}

.disabled {
  background: #ededed;
}
.hidden {
  display: none;
}

.limit-view {
  width: calc(100% - 50px);
}
