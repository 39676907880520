@use "../../../../assets/scss/font";

.item {
  & * {
    font-family: var(--font-family-regular);
  }
  min-height: 46px;
  display: flex;
  flex-direction: column;
  width: 100%;
  .label-normal {
    max-height: 22px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-size: 16px;
    line-height: 136%;
  }
  .label {
    @include font.bold;
    max-height: 22px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-size: 16px;
    line-height: 136%;
  }
  .fake_label {
    height: 22px;
  }
  .input {
    margin-top: 8px;
    border-radius: 6px;
  }
  .error {
    max-height: 20px;
    font-size: 13px;
    color: #d94d27;
    line-height: 20px;
    //scroll to error feild
    scroll-margin-top: 160px;
  }
}
