.panagition{
    max-width: 224px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
   
}
.prev, .next, .item{
    width: 32px;
    height: 100%;
    cursor: pointer;
    border-radius: 4px;
}
.item{
    color: #7A7A7A;
}
.prev svg{
    transform: rotate(90deg);
}
.next svg{
    transform: rotate(-90deg);
}
.checked path{
    fill: black;
}
.notchecked path{
    fill: #A5A5A5;
}

.prev:hover, .next:hover, .item:hover{
    background-color: rgba(128, 128, 128, 0.104);
}

.itemchecked{
    background: #F0B71C !important;
    color: black !important;
}

