.displayFlex {
  display: flex;
  flex-direction: column;
}

.displayGrid {
  display: grid;
}
.item {
  display: flex;
  min-height: 26px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  .checkbox {
    width: 20px;
    height: 20px;
    border-radius: 3px;
  }
  .nochecked {
    border: 1px solid rgba(128, 128, 128, 0.43);
  }
  .checked {
    background: #f0b71c;
  }
  .checkbox:hover {
    box-shadow: 0px 0px 1px 1px #f0b71c;
    border: 1px solid #f0b71c;
  }
  .label {
    font-weight: 400;
    font-size: 16px;
    color: #7a7a7a;
    line-height: 26px;
    min-height: 26px;
  }
}
