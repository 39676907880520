.select-type {
  font-size: 16px;

  :global
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: black !important;
  }
  :global .ant-select-arrow {
    color: black !important;
  }
  .select-type {
    :global .ant-select-selection-search-input {
      line-height: 32px;
    }
  }
}
