.performance-history {
  font-size: 14px;
  .status {
    display: flex;
    width: 100%;
    height: 26px;
    padding: 3px 12px;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 17px;
    align-self: center;
    justify-self: center;
    white-space: nowrap;
    
text-align: center;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 23px; /* 176.923% */
    &.approved {
      background: #d9eedb;
      color: #41ac49;
    }
    &.pending {
      background: #fcefdf;
      color: #e9912a;
    }
    &.sent {
      background: #fcefdf;
      color: #e9912a;
    }
    &.rejected {
      background: #fcf0ed;
      color: #ef4949;
    }
    &.new {
      background: #e5edf8;
      color: #248fed;
    }
  }
  :global .ant-table-thead > tr > th {
    color: var(--text-colors-text-heading-dark, #000);
    font-size: 14px;
    font-weight: 500;
    line-height: 136%; /* 19.04px */
    padding: 10px !important;
  }
  :global .ant-table-thead > tr > td {
    color: var(--text-colors-text-heading-dark, #000);
    font-size: 14px;
    font-weight: 500;
    line-height: 136%; /* 19.04px */
    padding: 10px !important;
  }
  :global .ant-table-thead > tr > th::before {
    content: none !important;
  }
  :global .ant-table-tbody > tr.ant-table-row:hover > td,
  :global .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: unset !important;
  }

  :global .ant-table-content {
    max-height: 800px;
    overflow: auto !important;
  }
  :global .ant-table-tbody > tr > td, :global .ant-table-thead > tr > td {
    padding: 10px;
  }
}
