.kpi-param-add {
  .kpi-add-param-wrapper {
    .ant-space {
      display: flex;
      padding: 12px;
      border-top: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
      align-items: center;
      height: 74px;
    }

    .ant-space-item:first-child {
      width: 40%;
    }

    .ant-space-item:not(first-child) {
      width: 25%;
    }

    .ant-space-item:last-child {
      width: 10%;
    }

    .ant-form-item {
      margin-bottom: unset;
    }

    .ant-input {
      min-width: 100px;
    }
  }

  .ant-input-group-addon {
    border: 1px solid #ededed;
  }

  .kpi-setup-goal {
    .ant-space-item:first-child,
    .ant-space-item:nth-child(2) {
      width: unset;
    }

    .ant-space-item:nth-last-child(3) {
      width: calc(100% - 310px);
    }

    .ant-space-item:nth-last-child(2) {
      width: 200px;
      text-align: center;
    }

    .ant-space-item:last-child {
      width: 100px;
    }
  }

  .ant-picker {
    width: 100%;
    height: 46px;
  }

  .kpi-add-kpi {
    .ant-space {
      min-height: 74px;
      height: unset;
      padding-bottom: 0;
    }

    .ant-form-item {
      margin-bottom: 24px;
    }

    .ant-space-item:first-child {
      width: 60px;
    }

    .ant-space-item:nth-last-child(3) {
      width: calc(100% - 315px);
    }

    .ant-space-item:nth-last-child(2) {
      width: 130px;
      text-align: center;
    }

    .ant-space-item:last-child {
      width: 100px;
    }

    .ant-input-group > .ant-input:first-child,
    .ant-input-group-addon:first-child {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  &.kpi-custom-multiselect {
    .ant-select-selection-overflow-item .ant-select-selection-item {
      margin-right: 3px;
      border-radius: 19px;
      background: rgb(237, 237, 237);
      height: 38px;
      padding: 6px 12px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.kpi-wrapper {
  .ant-select-selector {
    height: 46px !important;
    display: flex;
    align-items: center;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    cursor: pointer;
  }

  .ant-select-clear {
    font-size: 14px;
    width: 14px;
    height: 14px;
  }

  .ant-select-selection-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 162.02%;
    color: #a5a5a5;
  }

  .ant-select-show-search,
  .ant-select {
    .ant-select-selection-search-input {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      // line-height: 162.02%;
      // min-height: 46px;
    }

    .ant-select-selection-overflow {
      .ant-select-selection-item {
        font-size: 16px;
        margin-top: 4px;
        line-height: 30px;
        background-color: #fbeac0;
        height: 30px;
        border-radius: 4px;
        margin-bottom: 4px;
        svg {
          margin-left: 6px;
        }
      }
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #ededed;
  }

  .ant-input {
    font-family: unset;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 162.02%;
    // padding: 10px 20px;
    height: 46px;
    border: 1px solid #ededed;

    &::placeholder {
      color: #a5a5a5;
    }
  }

  .ant-radio-wrapper {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 162.02%;
    color: #7a7a7a;

    .ant-radio-checked .ant-radio-inner {
      border-color: transparent;
      background: #f0b71c;
    }

    .ant-radio-inner::after {
      background: #434243;
    }
  }

  .ant-form-item {
    margin-bottom: 18px;

    .ant-form-item-label > label {
      font-weight: 500;
      font-size: 16px;
      line-height: 136%;
      height: unset;
      display: inline-block;
      font-family: var(--font-family-medium);
    }
  }

  .ant-switch-checked {
    background: #f0b71c;
  }

  .ant-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    width: 160px;
    height: 45px;
    background: #ffffff;
    border: 1px solid #ededed;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #000000;
    font-family: unset;
    font-weight: 700;

    &.ant-btn-primary {
      background: #f0b71c;
      border: 1px solid #f0b71c;
    }

    &.ant-btn-icon-only {
      width: 46px;
      padding: 14px;
      height: 46px;
    }
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #ededed;
    background: #f5f5f5;

    svg path {
      stroke: #d9d9d9;
    }
  }

  .ant-modal-header {
    border-bottom: 0;
    border-radius: 6px 6px 0px 0px;
    padding: 18px 16px;
    gap: 10px;
    background: #fafafb;

    .ant-modal-title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 136.52%;
    }
  }

  &.ant-modal-confirm {
    .ant-modal-body {
      padding: 32px 10px;
    }

    .ant-modal-confirm-content {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      color: #000000;
      margin-top: 0;

      svg {
        width: 40px;
        height: 40px;
        cursor: default;
      }

      p {
        margin-top: 10px;
        font-weight: 400;
        font-size: 20px;
        line-height: 136.02%;
      }
    }

    .ant-modal-confirm-btns {
      display: flex;
      justify-content: center;
      margin-top: 22px;

      .ant-btn {
        width: 200px;
      }
    }
  }

  .kpi-select-dropdown {
    > svg path {
      stroke: #f0b71c;
      fill: #f0b71c;
    }
  }

  &.ant-notification-notice {
    padding: 0;
    border-radius: 6px;
    width: auto;

    .ant-notification-notice-with-icon {
      display: flex;
      justify-content: start;
      align-items: center;
      padding: 0 20px;
      min-height: 55px;

      svg {
        width: 32px;
        height: 32px;
        cursor: default;
      }

      .ant-notification-notice-icon {
        position: relative;
      }

      .ant-notification-notice-message {
        font-size: 16px;
        margin-left: 10px;
        padding-right: 0;
        margin-bottom: unset;
      }

      .ant-notification-notice-description {
        margin-left: unset;
      }
    }

    .ant-notification-notice-close {
      display: none;
    }
  }

  .kpi-input-search.ant-input-group-wrapper {
    .ant-input {
      border-right: 0;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }

    button {
      border-left: 0;
      border-bottom-right-radius: 6px !important;
      border-top-right-radius: 6px !important;
    }

    .ant-input-group-addon {
      background: transparent;
      border-bottom-right-radius: 6px !important;
      border-top-right-radius: 6px !important;
    }
  }

  .ant-modal-close {
    top: 10px;
  }

  .min-height-268 {
    min-height: 268px;
    height: 268px;
  }

  .kpi-table {
    tr {
      font-style: normal;
      font-size: 16px !important;

      td,
      th {
        padding: 16px !important;
        min-height: 52px !important;
        height: unset !important;
      }

      &:hover {
        background: #fafafb;
      }
    }
  }

  .kpi-table-antd {
    th {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      background: #ffffff;
      padding: 18px 10px;

      &::before {
        content: unset !important;
      }
    }

    td {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 162.02%;
      background: #ffffff;
      padding: 18px 10px;
    }

    tr.ant-table-row-selected > td {
      background: transparent;
    }
  }

  .ant-checkbox:hover::after,
  .ant-checkbox-wrapper:hover .ant-checkbox::after,
  .ant-checkbox-checked::after {
    border-color: #bcb3e2;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #bcb3e2;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #f0b71c;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f0b71c;
    border-color: #f0b71c;

    &::after {
      border-color: #434243;
    }
  }

  .text-center {
    text-align: center;
  }

  .ant-input:focus,
  .ant-input-focused,
  .ant-input:hover,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #ededed;
    box-shadow: unset !important;
  }

  .ant-picker {
    width: 100%;
    height: 46px;
    padding: 10px 16px 10px 20px !important;
    border: 1px solid #ededed;

    input::placeholder {
      color: #a5a5a5;
    }

    .ant-picker-suffix {
      color: #7a7a7a;
    }
  }

  .ant-picker-focused {
    border-color: #ededed !important;
    box-shadow: unset;
  }

  .ant-upload-list-item {
    margin-top: 10px;
    height: 46px !important;
    padding-right: 10px;
  }

  .ant-select-clear {
    font-size: 14px;
    width: 14px;
    height: 14px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    // padding: 0 20px;
    border: 1px solid #ededed;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    // left: 20px;
    right: 32px;
  }

  .ant-input {
    textarea {
      height: auto !important;
    }
  }
}

.result-management {
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: unset !important;
  }
  .ant-input[disabled] {
    color: #000000;
  }
}

.ant-picker-dropdown.kpi-wrapper {
  .ant-picker-panel-container {
    border-radius: 6px;

    .ant-picker-cell-selected {
      .ant-picker-cell-inner {
        background-color: var(--primary-color);
        color: black;
        border-radius: 6px;
      }

      .ant-picker-cell-inner::before {
        border: 1px solid var(--primary-color);
        border-radius: 6px;
      }
    }

    .ant-picker-cell-today {
      .ant-picker-cell-inner::before {
        border: 1px solid var(--primary-color);
        border-radius: 6px;
      }
    }
  }
}

.modal-final-score {
  width: 600px !important;
  .ant-modal-header {
    background: #fafafb;
    color: #000000;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    .ant-modal-title {
      font-weight: 700;
      font-size: 20px;
    }
  }
  .ant-modal-close-x {
    svg {
      margin-top: 15px;
      display: inline;
    }
  }
  .ant-modal-body {
    padding: 24px;
  }
  .text-error {
    color: #ff4d4f;
    padding-left: 8px;
    position: relative;
  }
}

.kpi-wrapper {
  .kpi-add-resource {
    .ant-space {
      display: flex;
      padding: 12px 0;
      // border-top: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
      align-items: center;
      min-height: 74px;
    }

    .ant-space-align-center {
      align-items: unset !important;
    }

    .ant-space-item {
      display: flex;
      align-items: flex-start;
    }

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 46px;
    }

    .ant-space-item:first-child {
      width: 294px;
    }

    .ant-space-item:not(first-child) {
      width: 146px;
    }

    .ant-space-item:last-child {
      width: 100px;
    }

    .ant-form-item {
      margin-bottom: unset;
    }

    .ant-input {
      min-width: 100px;
    }

    .ant-select-selector {
      padding: 0 !important;
    }
  }

  .table-scroll-fixed-header.hod-table {
    // .ant-table-body {
    //   max-height: calc(100vh - 440px) !important;
    //   overflow-y: auto !important;
    // }

    .ant-table-empty table {
      table-layout: unset !important;
    }

    th.ant-table-cell {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 136%;
      min-height: 52px;
      background: #ffffff;
      border-bottom-color: #ffffff;

      &::before {
        content: unset !important;
      }
    }

    td.ant-table-cell {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 136%;
      min-height: 52px;
      border-bottom-color: #ffffff;
    }

    .ant-table-row-level-1 .ant-table-cell {
      font-size: 14px;
      border-bottom: 0;
    }

    .ant-table-row-level-1 .ant-table-cell:last-child > div {
      display: none;
    }
  }

  .ant-radio {
    &:hover {
      .ant-radio-inner {
        border-color: #f0b71c;
      }
    }

    .ant-radio-inner {
      box-shadow: none;
    }
  }

  .ant-form-item-label > label::after {
    content: '';
  }

  .ant-select-tree-checkbox {
    margin: 4px 8px 0 20px !important;
  }

  .ant-space-align-baseline {
    align-items: unset !important;
  }

  .ant-picker-status-error.ant-picker,
  .ant-picker-status-error.ant-picker:not([disabled]):hover {
    border-color: #ff4d4f !important;
  }

  // .ant-select-selection-overflow {
  //   padding-left: 10px !important;
  // }
  // .ant-select-selection-placeholder {
  //   padding-left: 10px !important;
  // }

  [ant-click-animating-without-extra-node='true']::after {
    display: none;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: '';
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }

  .icon-expanded {
    transform: rotate(90deg);
    transition: all ease 0.3s;
  }

  .icon-arrow-tree {
    transition: all ease 3s;
    margin-top: 2px;
    margin-right: 20px;
  }

  .input-search-tree {
    margin-bottom: 20px;
    border-color: #ededed !important;
    box-shadow: unset !important;
    .ant-input {
      height: 34px !important;
    }
    .ant-input-suffix {
      color: #7a7a7a;
      margin-right: 8px;
    }
  }

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: unset;
  }

  .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled {
    display: none;
  }

  .hidden-required-mark {
    .ant-form-item-label
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::after {
      display: none !important;
    }
  }

  .ant-form-item-explain-error {
    padding-left: 8px;
    position: relative;
  }
  .ant-form-item-explain-error::before {
    content: '*';
    position: absolute;
    left: 0;
  }

  .text-error {
    color: #ff4d4f;
    padding-left: 8px;
    position: relative;
  }

  .text-error::before {
    content: '*';
    position: absolute;
    left: 0;
  }

  .custom-margin-text-error {
    .ant-form-item-explain-error {
      margin-bottom: 8px;
    }
  }
  .ant-select-tree-switcher.ant-select-tree-switcher_open {
    .ant-select-tree-switcher-icon {
      transform: rotate(180deg);
    }
  }

  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
    height: 100%;

    .ant-select-selection-search,
    input {
      height: 100%;
    }
  }

  .tree-select-custom .ant-select-selection-item {
    visibility: visible !important;
  }

  .tree-select-custom,
  .tree-select-multiple-custom {
    .ant-select-selection-search {
      display: none !important;
    }
  }

  .table-tree-custom {
    .ant-table-empty table {
      table-layout: unset !important;
    }

    th.ant-table-cell {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 136%;
      min-height: 52px;
      background: #ffffff;
      // border-bottom-color: #ffffff;

      &::before {
        content: unset !important;
      }
    }

    td.ant-table-cell {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 136%;
      min-height: 52px;
      // border-bottom-color: #ffffff;
      // display: inline-flex;
      // align-items: center;
    }

    .ant-table-row-level-1 .ant-table-cell {
      // font-size: 14px;
      // border-bottom: 0;
    }

    // .ant-table-row-level-1 .ant-table-cell:last-child > div {
    //   display: none;
    // }

    thead {
      tr:nth-child(2) {
        th {
          padding: 4px;
          font-weight: 400 !important;
        }
      }

      tr:nth-child(1) {
        th {
          padding-top: 4px;
          padding-bottom: 4px;
        }
      }
    }
  }

  .ant-form-item-has-error {
    textarea {
      border: 1px solid #ff4d4f !important;
    }
  }

  .table-tree-custom-empty {
    .ant-checkbox-wrapper {
      display: none;
    }
  }

  .ant-select-selector {
    .ant-tag {
      .option-check-icon {
        display: none !important;
      }
    }
  }
}

.kpi-wrapper.dropdown-object-tree {
  padding: 12px;
  min-width: 470px !important;
  border-radius: 6px;
  .ant-select-tree-checkbox.ant-select-tree-checkbox-checked {
    .ant-select-tree-checkbox-inner {
      background-color: #f0b71c;
      border-color: #f0b71c;
    }

    .ant-select-tree-checkbox-inner::after {
      border-color: #000000;
    }
  }

  .ant-select-tree-checkbox.ant-select-tree-checkbox-indeterminate {
    .ant-select-tree-checkbox-inner::after {
      background-color: unset;
    }
  }

  .ant-select-tree-checkbox:hover::after {
    border-color: #f0b71c;
  }

  .ant-select-tree-treenode {
    .ant-select-tree-node-content-wrapper {
      background-color: white;
    }
    .ant-select-tree-node-content-wrapper {
      .ant-checkbox-wrapper {
        margin-left: 0;
      }
      .ant-checkbox {
        display: flex;
      }
      .ant-checkbox + span {
        padding: 0;
      }
      .ant-checkbox-checked {
        display: none;
      }
    }
    .ant-select-tree-node-selected {
      .ant-checkbox {
        display: none;
      }
      .ant-checkbox-checked {
        display: flex;
      }
    }
  }
}

.kpi-wrapper.dropdown-object-tree.dropdown-object-tree-check-one {
  .ant-select-tree-icon__customize {
    display: none;
  }
}

// .virtual-table .ant-table-container:before,
// .virtual-table .ant-table-container:after {
//   display: none;
// }
// .virtual-table-cell {
//   box-sizing: border-box;
//   padding: 16px;
//   border-bottom: 1px solid #e8e8e8;
//   background: #fff;
// }
// [data-theme='dark'] .virtual-table-cell {
//   box-sizing: border-box;
//   padding: 16px;
//   border-bottom: 1px solid #303030;
//   background: #141414;
// }

.kpi-wrapper {
  .ant-select-tree-switcher {
    .ant-select-tree-switcher-icon {
      transition: all ease 0.3s;
    }
  }

  .ant-select-tree-switcher.ant-select-tree-switcher_open {
    .ant-select-tree-switcher-icon {
      transform: rotate(180deg);
    }
  }

  .ant-select-tree-switcher.ant-select-tree-switcher_close {
    .ant-select-tree-switcher-icon {
      transform: rotate(0deg);
    }
  }

  button[disabled]:active {
    transform: unset;
  }
}

.kpi-select-custom {
  .ant-select-item-option-content > .demo-option-label-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .option-check-icon {
      display: none;
    }
  }
}
