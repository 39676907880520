.container-body-modal {
  flex: 1;
  overflow: auto;
  scroll-behavior: smooth;

  &:-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
}
