.reactSwitchCheckbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.reactSwitchLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 40px;
  height: 22px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.reactSwitchCheckbox:checked + .reactSwitchLabel {
  background: var(--primary-color);
}

.reactSwitchLabel .reactSwitchButton {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.reactSwitchCheckbox:checked + .reactSwitchLabel .reactSwitchButton {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.reactSwitchLabel:active .reactSwitchButton {
  width: 22px;
}