.content {
  min-height: 41px;
  width: 100%;
  position: relative;
  padding-left: 46px;
  display: flex;
  align-items: center;

  .icon {
    width: 32px;
    height: 32px;
    left: 0px;
    border-radius: 100%;
    position: absolute;
  }

  .button {
    position: absolute;
    width: 140px;
    height: 33px;

    background: #d9eedb;
    border-radius: 17px;
    right: 0px;

    & > div {
      border-radius: 17px;
    }
  }

  .orange {
    background-color: #f0b71c;
  }

  .primary {
    background-color: #17345b;
  }

  .green {
    background-color: #41ac49;
  }

  .yellow {
    background: #f0b71c;
  }

  .tomato {
    background: #d94d27;
  }

  .item {
    min-height: 41px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .text {
      font-size: 1rem;
      display: flex;
      gap: 3px;
      min-height: 26px;
      width: 100%;
      padding: 3px 0px;
      & * {
        font-size: 1rem !important;
      }
    }

    .sub_text {
      width: 100%;
      min-height: 15px;
    }
  }
}

.hr {
  width: 0px;
  height: 40px;
  border-left: 1px solid #ededed;
  margin-left: 16px;
}
