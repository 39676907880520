.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  :global .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    fill: var(--primary-colors-brand-brand-primary-yellow, #f0b71c);
  }
  
}
.header-title {
  display: flex;
  justify-content: space-between;
}
.title {
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.body {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.d-flex {
  display: flex;
}
.btn-download {
  margin-left: 8px;
}
