.toggle-icon{
    height: 18px;
    width: 18px;
    background-color: #7A7A7A;
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    padding: 4px;
    top:13px
}