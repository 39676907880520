

.value {
    font-weight: 400;
    font-size: 16px;
    color: #7A7A7A;
    margin-left: 10px;
    height: 20px;
    line-height: 20px;
}
