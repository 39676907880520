.dropdown {
  position: relative;
}

.active svg[accumulate="none"] {
  fill: var(--primary-color);
}

.dd-menu {
  position: absolute;
  width: 250px;
  border-radius: 4px;
  top: calc(100% + 5px);
  transform: translate(-50%, 0);
  z-index: var(--z-menu);
  padding: 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  animation-name: mounting;
  animation-duration: 250ms;
  @keyframes mounting {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
