.wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
}

.sidebar {
  position: sticky;
  top: 0;
  flex-shrink: 0;
  width: var(--sidebar-width-lg);
  position: fixed;
  background-color: white;
  overflow-y: auto;
  height: 100%;
  z-index: 100;
}
.sidebar::-webkit-scrollbar {
  width: 1px;
}
.sidebar::-webkit-scrollbar-thumb {
  width: 1px;
  background-color: white;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1db;
  padding-left: var(--sidebar-width-lg);
}

.overflowHidden {
  overflow: hidden;
}

.header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  height: var(--header-height);
  background-color: #fff;
  margin-left: 2px;
  z-index: 100;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  padding: 8px 20px 18px 20px;
 
}

