.content {
  background: #fff;
  padding: 100px 0;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.content img {
  width: 273px;
  height: 273px;
}
.content p {
  padding-top: 20px;
}