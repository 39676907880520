.btn {
  box-sizing: border-box;
  padding: 10px 18px;
  white-space: nowrap;
  width: fit-content;
  outline: none;
  border: none;
  height: 46px;
  border-radius: 6px;
  transition: 150ms ease-in;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: var(--normal-font-size);
  font-family: var(--font-family);
  font-weight: var(--font-weight-medium);
  background: none;
  cursor: pointer;
  > span:first-of-type {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &:active {
    transform: scale(0.95);
  }
  &:disabled {
    opacity: 0.7;
  }
}
.btn-visible {
  display: flex;
}
.btn-hidden {
  display: none;
}
.btn > *,
.btn-icon > * {
  pointer-events: none;
}

.btn-icon {
  padding: 0;
  &:disabled {
    opacity: 0.3;
  }
}

.btn-height-sm {
  font-size: 14px;
  height: 36px;
  padding: 6px 12px;
}

.btn-height-sm-1 {
  font-size: 16px;
  height: 42px;
}

.btn-height-md {
  height: 46px;
}

.btn-size-sm {
  min-width: 91px;
}

.btn-size-sm-1 {
  min-width: 140px;
}

.btn-size-md {
  min-width: 155px;
}

.btn-size-vmd {
  min-width: 159px;
}

.btn-size-lg {
  min-width: 180px;
}

.btn-size-vlg {
  min-width: 200px;
}

.btn-icon-size-sm {
  min-width: 36px;
  height: 36px;
}

.btn-icon-size-sm {
  min-width: 46px;
  height: 46px;
}

.btn-icon-size-md {
  min-width: 50px;
  height: 50px;
}

.dense-sm {
  padding: 10px 8px;
}

.dense-md {
  padding: 10px 16px;
}

.standard {
  border: none;
}

.btn-outlined {
  background: none;
  border: 1px solid #d8d8d8;
  color: #000000;
}

.btn-filled {
  border-width: 1px;
  border-style: solid;
}

.btn-neutral {
  border-color: #ffffff;
  background-color: #ffffff;
  color: #a5a5a5;
}

.btn-primary {
  border-color: #f0b71c;
  background-color: #f0b71c;
  color: #000000;
}

.btn-clear {
  border-color: #d8d8d8;
  background-color: #ffffff;
  color: #000000;
}

.btn-secondary {
  border-color: #434243;
  background-color: #434243;
  color: #ffffff;
}

.btn-tertiary {
  border-color: #17345b;
  background-color: #17345b;
  color: #ffffff;
}

.btn-icon-outlined {
  background: none;
  border: 1px solid #d8d8d8;
}

.btn-icon-filled {
  border-width: 1px;
  border-style: solid;
}

.btn-icon-primary {
  border-color: #fafafb;
  background-color: #fafafb;
}
