.container {
    width: 100%;
  height: 100%;
  margin: 4px 0px;
  overflow-x: auto;

}

.container::-webkit-scrollbar-thumb {
    background-color: #5f5f5f;
  border-radius: 5px;


}

.container::-webkit-scrollbar {
    height: 4px;
}

.content {
    height: 100%;
    display: -webkit-box;
}

.title {
    padding: 0px 5px;
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    
}

.title:hover {
    background-color: rgba(128, 128, 128, 0.062);
    cursor: pointer;
}

.item {
    min-height: 52px;
    display: flex;
    align-items: center;
    padding: 0px 5px;
    font-size: 14px;
}
.center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.cursor{
    cursor: pointer;
}
