.lds-ring {
  display: inline-block;
  position: relative;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 4rem;
    height: 4rem;
    margin: 0.5rem;
    border: 0.5rem solid var(--primary-color);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--primary-color) transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

.md {
  width: 3.75rem;
  height: 3.75rem;
  div {
    width: 2.75rem;
    height: 2.75rem;
    margin: 0.375rem;
    border: 0.25rem solid var(--primary-color);
    border-color: var(--primary-color) transparent transparent transparent;
  }
}

.sm {
  width: 2rem;
  height: 2rem;
  div {
    width: 1.25rem;
    height: 1.25rem;
    margin: 0.25rem;
    border: 0.188rem solid var(--primary-color);
    border-color: var(--primary-color) transparent transparent transparent;
  }
}

.xs {
  width: 1.6rem;
  height: 1.6rem;
  div {
    width: 1rem;
    height: 1rem;
    margin: 0.25rem;
    border: 0.188rem solid var(--primary-color);
    border-color: var(--primary-color) transparent transparent transparent;
  }
}
.black div {
  border: 0.188rem solid #000;
  border-color: #000 transparent transparent transparent;
}

.white div {
  border: 0.188rem solid #fff;
  border-color: #fff transparent transparent transparent;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
