.container-bottom-modal {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 16px;
  border-top: 1px solid #ededed;

  & > button {
    cursor: pointer;
  }
}
