.status {
    display: flex;
    width: 100%;
    padding: 5px 12px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 17px;
    align-self: center;
    justify-self: center;
    white-space: nowrap;
    cursor: pointer;
    &.approved {
      background: #d9eedb;
      color: #41ac49;
    }
    &.pending {
      background: #fcefdf;
      color: #e9912a;
    }
    &.sent {
      background: #fcefdf;
      color: #e9912a;
    }
    &.rejected {
      background: #fcf0ed;
      color: #ef4949;
    }
    &.new {
      background: #e5edf8;
      color: #248fed;
    }
  }