.title-text {
  font-family: var(--font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 136.523%;
}

.title_field {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 4px;
}

.small-goldtext {
  color: var(--primary-color);
  font-family: var(--font-family-regular);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}
