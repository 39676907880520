.container_snackbar {
  position: fixed;
  min-width: 400px;
  min-height: 55px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  background-color: white;
  animation-direction: normal;
  box-shadow: 0px 0px 6px 1px rgba(56, 56, 52, 0.1);
}

.container_snackbar label {
  font-size: 20px;
  margin-left: 24px;
}

.leftTop {
  left: 20px;
  top: 10px;
  animation: leftTop 0.5s;
}

@keyframes leftTop {
  0% {
    left: 0px;
  }

  100% {
    left: 20px;
  }
}

.rightTop {
  right: 20px;
  top: 10px;
  animation: rightTop 0.5s;
}

@keyframes rightTop {
  0% {
    right: 0px;
  }

  100% {
    right: 20px;
  }
}

.leftBotom {
  left: 10px;
  bottom: 10px;
  animation: leftBotom 0.5s;
}

@keyframes leftBotom {
  0% {
    left: 0px;
  }

  100% {
    left: 20px;
  }
}

.rightBotom {
  right: 10px;
  bottom: 10px;
  animation: rightBotom 0.5s;
}

@keyframes rightBotom {
  0% {
    right: 0px;
  }

  100% {
    right: 20px;
  }
}
