#container-modal {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  z-index: var(--z-modal);
}

.container_content_modal {
  box-sizing: border-box;
  width: 800px;
  max-height: 80vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 0.375rem;
  animation-duration: 250ms;
}

.modal_mounting {
  animation-name: mounting-modal;
}
.modal_unmounting {
  animation-name: unmounting-modal;
}
@keyframes mounting-modal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes unmounting-modal {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.modal-sm {
  width: 500px;
}
