.container-header-modal {
  background-color: #fafafb;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 18px 16px;

  p {
    font-size: 20px;
    margin: 0;
    line-height: 28px;
  }
}

.container-close-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }
}
