.draff button,
.watting button,
.activated button,
.expire button,
.reject button {
  width: auto;
  height: auto;
  margin: 12px 0px 0px 0px;
  font-size: 13px;
  font-weight: 400;
}

.draff button {
  border-radius: 17px;
  color: #e9912a;
  background: #fcefdf;
}

.watting button {
  border-radius: 17px;
  color: #e9912a;
  background: #fcefdf;
}

.activated button {
  border-radius: 17px;
  color: #41ac49;
  background: #d9eedb;
}

.expire button {
  border-radius: 17px;
  color: #ef4949;
  background: #fde3e4;
}
.reject button {
  border-radius: 17px;
  color: #ef4949;
  background: #fde3e4;
}
.textStatus {
  font-size: 13px;
}

.customButon {
  padding: 5px 12px;
  line-height: 23px;
}
