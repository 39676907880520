.select {
    :global .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
      fill: var(--primary-colors-brand-brand-primary-yellow, #f0b71c);
    }
    :global .ant-select-selection-overflow-item {
      max-width: 45% !important;
    }
  }
  .tag-number-1 {
    :global .ant-select-selection-overflow-item {
      max-width: 90% !important;
    }
  }
  .tag-number-2 {
    :global .ant-select-selection-overflow {
      width: 100%;
    }
  }
  