.mobile-detected-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  gap: 30px;

  .message {
    font-size: 16px;
    font-weight: 500;
    line-height: 21.76px;
    text-align: center;
    margin: 0 20px 0 20px;
  }
}