@import "./antd.custom.scss";
@import "./assets/scss/kpi.scss";
@import "./assets/scss/systemManagement.scss";
@import "./assets/scss/typography.scss";

@font-face {
  font-family: "GoogleSans Regular";
  src: url("./assets/fonts/GoogleSans-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GoogleSans Medium";
  src: url("./assets/fonts/GoogleSans-Medium.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GoogleSans Bold";
  src: url("./assets/fonts/GoogleSans-Bold.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/*============== VARIABLE CSS ================*/
:root {
  --header-height: 3.75rem;
  --sidebar-width: 10rem;
  --sidebar-width-lg: 15rem;

  /* Colors */
  --primary-color: #f0b71c;
  --secondary-color: #17345b;
  --info-color: #248fed;
  --text-color: #000000;
  --body-color: #fff;
  --neutral-color: #ededed;
  --text-white: #fff;
  --text-muted: #a5a5a5;
  --text-danger: #d94d27;
  --color-danger: #d94d27;
  --text-success: #47c751;
  --bg-gray-color: #fafafb;
  --hver-light-yellow-color: #f3ecd8;
  /* Font-family */
  --font-family-regular: "GoogleSans Regular", sans-serif;
  --font-family-medium: "GoogleSans Medium", sans-serif;
  --font-family-bold: "GoogleSans Bold", sans-serif;
  --font-family: "GoogleSans Regular", sans-serif;
  /* Size */
  /* --normal-font-size: 0.875rem;
  --small-font-size: 0.75rem; */
  --three-extreme-large-font-size: 2.125rem;
  --two-extreme-large-font-size: 1.5rem;
  --extreme-large-font-size: 1.25rem;
  --large-font-size: 1.125rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;

  /* Font-weight */
  --font-weight-medium: 400;
  --font-weight-bold: 700;

  /* Z index */
  --z-fixed: 100;
  --z-tooltip: 50;
  --z-menu: 100;
  --z-date-picker: 50;
  --z-header-table: 10;
  --z-index-collapse: 200;
  --z-modal: 1000;

  /* other */
  --scroll-bar-color: #e3e2e9;
  --scroll-thumb-color: #b8b8bb;
  --background-color: #ededed;

  /* tree-component*/
  --width-tree-line: 16px;
  --height-tree-line: 6px;
  --color-border-tree: #ededed;
}

/* Font size for large devices */
/* @media screen and (min-width: 968px) {
  :root {
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
  }
} */

/* ======== RESET ========== */
*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

*:disabled {
  cursor: not-allowed;
}

/*========== SCROLL BAR ==========*/
*::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
  background-color: var(--scroll-bar-color);
  border-radius: 0.5rem;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-color);
  border-radius: 0.5rem;

  &:hover {
    background-color: #a2a2a5;
  }
}

/* ======== LAYOUT ========== */
body {
  font-family: var(--font-family-regular);
  color: var(--text-color);
  background-color: #f1f1f1db;
  min-height: 0;

  &.modal-open {
    overflow: hidden;
  }
}

/* ======== DISABLE UP/DOWN ARROW INPUT NUMBER ========== */
//Firefox
input[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* ======== SHORT-HAND-CSS ========== */

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.color-a5a5a5 {
  color: #a5a5a5;
}

.f-3xl {
  font-family: var(--font-family-regular);
  font-size: var(--three-extreme-large-font-size);
}

.f-2xl {
  font-family: var(--font-family-regular);
  font-size: 28px;
}

.f-xl {
  font-family: var(--font-family-regular);
  font-size: var(--extreme-large-font-size);
}

.f-lg {
  font-family: var(--font-family-regular);
  font-size: var(--large-font-size);
}

.f-md {
  font-family: var(--font-family-regular);
  font-size: var(--normal-font-size);
}

.f-sm {
  font-family: var(--font-family-regular);
  font-size: var(--small-font-size);
}

.f-xs {
  font-family: var(--font-family);
  font-size: 12px;
}

.fw-400 {
  font-weight: var(--font-weight-medium);
}

.fw-regular {
  font-family: var(--font-family-regular);
  font-weight: 400;
}

.fw-bold {
  font-family: var(--font-family-medium);
  font-weight: 500;
}

.fw-bolder {
  font-family: var(--font-family-bold);
  font-weight: 700;
}

.tali-center {
  text-align: center;
}

.tali-left {
  text-align: left;
}

.tali-right {
  text-align: right;
}

.d-flex {
  display: flex;
}
.d-block {
  display: block;
}

.fdr-row {
  flex-direction: row;
}

.fdr-col {
  flex-direction: column;
}

.jtf-center {
  justify-content: center;
}

.jtf-flex-start {
  justify-content: flex-start;
}

.jtf-flex-end {
  justify-content: flex-end;
}

.jtf-space-between {
  justify-content: space-between;
}

.ali-center {
  align-items: center;
}

.ali-end {
  align-items: end;
}

.ln-h-22 {
  line-height: 22px;
}
.input-title {
  line-height: 22px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.flex-1 {
  flex: 1;
}

.gap-4 {
  gap: 4px;
}
.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.gap-18 {
  gap: 18px;
}

.gap-20 {
  gap: 20px;
}

.gap-22 {
  gap: 22px;
}

.gap-24 {
  gap: 24px;
}

.gap-30 {
  gap: 30px;
}

.gap-40 {
  gap: 40px;
}

.p-6 {
  padding: 6px;
}

.p-7 {
  padding: 7px;
}

.p-10 {
  padding: 10px;
}

.py-6 {
  padding: 6px 0;
}

.pr-46 {
  padding-right: 46px !important;
}
.pl-16 {
  padding-left: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.px-14 {
  padding-left: 14px;
  padding-right: 14px;
}

.px-20 {
  padding: 0 20px;
}

.px-46 {
  padding-left: 46px;
  padding-right: 46px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-half-rem {
  margin-bottom: 0.5rem;
}

.mt-6 {
  margin-top: 6px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-24 {
  margin-top: 24px;
}
.mx-6 {
  margin: 0 6px;
}
.mx-20 {
  margin: 0 20px;
}

.mx-24 {
  margin: 0 24px;
}

.mx-46 {
  margin: 0 46px;
}

.my-28 {
  margin-top: 28px;
  margin-bottom: 28px;
}

.my-16 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mt-24 {
  margin-top: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-40 {
  margin-bottom: 40px;
}

.px-10 {
  padding: 0 10px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.min-w-100 {
  min-width: 100px;
}

.w-120 {
  width: 120px;
}

.w-110 {
  width: 110px;
}

.w-100 {
  width: 100px;
}

.w-80 {
  width: 80px;
}

.cursor-pointer {
  cursor: pointer;
}

.textarea-cant-resize {
  resize: none;
}

.asterisk {
  color: var(--primary-color);
  font-weight: var(--font-weight-medium);
}

.center {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.text-bold {
  font-family: var(--font-family-medium);
  font-weight: 500;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.due-completion-date {
  text-align: center !important;
  color: #000000 !important;
}

.text-primary {
  color: var(--primary-color);
}

.success {
  background: #d9eedb;
  border-radius: 15px;
  color: #41ac49;
}

.waiting {
  background: #fcefdf;
  border-radius: 15px;
  color: #e9912a;
}

.error {
  background: #fde3e4;
  border-radius: 15px;
  color: var(--text-danger);
}

.cancel {
  background: #fde3e4;
  border-radius: 15px;
  color: #ef4949;
}

.closed {
  background: #ededed;
  border-radius: 15px;
  color: #666666;
}

.status-container {
  height: 33px;
  right: 40px;
  width: 100%;
}

.status-opacity {
  opacity: 0.8;
}

.status-opacity:hover {
  opacity: 1;
}

.status-automission {
  padding: 12px;
  border-radius: 8px;
  width: initial !important;
}

.border-error {
  border: 1px solid var(--text-danger);
}

.radio {
  width: 20px;
  height: 20px;
  border: 2px solid #d8d8d8;
  border-radius: 100%;
  cursor: pointer;
}

.radioc {
  width: 20px;
  height: 20px;
  border: 5px solid var(--primary-color);
  border-radius: 100%;
  background: #434243;
  cursor: pointer;
}

.disable-selecbox {
  font-weight: 400;
  font-size: 16px;
  padding-left: 10px;
  color: #666666;
}

.color_require {
  color: #f0b71c !important;
}

.ant-input {
  border-radius: 6px !important;
}

.ant-input-affix-wrapper {
  border-radius: 6px !important;
}

.ant-picker {
  border-radius: 4px !important;
}

.ant-select-selector {
  border-radius: 6px !important;
}

.ant-modal-content {
  border-radius: 6px !important;
}

.ant-input-number {
  border-radius: 6px !important;
}

svg {
  cursor: pointer;
}

.notFound {
  color: gray;
  text-align: center;
}

.checkdata {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tox-tinymce {
  border-radius: 6px !important;
}

// custom css for selected antd on page workgroup
.view-content-create-workgroup
  .ant-select.wk-custom-select-antd
  .ant-select-selection-overflow-item
  .ant-select-selection-item {
  display: none;
}

.view-content-create-workgroup
  .ant-select.wk-custom-select-antd
  .ant-select-selection-overflow {
  .ant-select-selection-search {
    min-width: 160px !important;
    position: relative;
    margin-left: 5px;
  }

  .ant-select-selection-search-input {
    position: relative;
    font-family: var(--font-family-regular), GoogleSans, sans-serif;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-select-selection-search {
    width: 100%;
  }

  .ant-select-selection-search-input:focus {
    background: #ffffff;
    z-index: 99999;
  }
}

.view-content-create-workgroup
  .ant-select.wk-custom-select-antd.ant-select-open
  .ant-select-selection-overflow::before {
  content: "" !important;
}

.view-content-create-workgroup
  .ant-select.wk-custom-select-antd
  .ant-select-show-search {
  display: none;
}

.view-content-create-workgroup .ant-select-auto-complete {
  min-height: 46px !important;
  display: flex;
  align-items: center;
}

.view-content-create-workgroup .ant-select-selector {
  min-height: 46px !important;
  display: flex;
  align-items: center;
}

.view-content-create-workgroup
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  min-height: 46px !important;
  display: flex;
  align-items: center;
}

.view-content-create-workgroup
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  min-height: 46px !important;
  display: flex;
  align-items: center;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  min-height: 46px !important;
  display: flex;
  align-items: center;
}

// apply all for antd
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #fff9e8;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: black;
}

// custom css for raido antd on page workgroup
.view-content-create-workgroup .ant-radio-checked .ant-radio-inner {
  border-color: #f0b71c;
  background-color: #f0b71c;
}

.view-content-create-workgroup .ant-radio-inner:after {
  background-color: #434243;
}
.view-content-create-workgroup .ant-select-auto-complete {
  min-height: 46px !important;
  display: flex;
  align-items: center;
}

.view-content-create-workgroup .ant-select-selector {
  min-height: 46px !important;
  display: flex;
  align-items: center;
}

.view-content-create-workgroup
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  min-height: 46px !important;
  display: flex;
  align-items: center;
}

.view-content-create-workgroup
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  min-height: 44px !important;
  display: flex;
  align-items: center;
}

.view-content-create-workgroup
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  min-height: 46px !important;
  display: flex;
  align-items: center;
}

// custom css for page workgroup detail
.view-content-detail-workgroup .ant-select-show-arrow {
  width: 100%;
}

.view-content-detail-workgroup .ant-select-single .ant-select-selector {
  display: flex;
  border: 1px solid #ededed !important;
  background: #ffffff;
  align-items: center;
  padding: 0 0.5rem 0 1.25rem !important;
  border-radius: 0.375rem !important;
  height: 2.875rem !important;
  width: 100% !important;
}

.view-content-detail-workgroup .ant-select-multiple {
  width: 100%;
  .ant-select-selector {
    display: flex;
    border: 1px solid #ededed !important;
    background: #ffffff;
    align-items: center;
    //padding: 0 0.5rem 0 1.25rem !important;
    border-radius: 0.375rem !important;
    min-height: 2.875rem !important;
    max-height: 7rem !important;
    width: 100% !important;
    overflow: auto;
  }
}

.view-content-detail-workgroup
  .ant-select-selector
  .ant-select-selection-overflow
  .ant-select-selection-item {
  background-color: #f5eace;
  border-radius: 0.375rem;
}

.view-content-workgroup
  .ant-select-selector
  .ant-select-selection-overflow
  .ant-select-selection-item {
  background-color: #f5eace;
  border-radius: 0.375rem;
}

.view-content-workgroup .ant-select-multiple {
  width: 100%;
  .ant-select-selector {
    display: flex;
    border: 1px solid #ededed !important;
    background: #ffffff;
    align-items: center;
    //padding: 0 0.5rem 0 1.25rem !important;
    border-radius: 0.375rem !important;
    min-height: 2.875rem !important;
    max-height: 7rem !important;
    width: 100% !important;
    overflow: auto;
  }
}

.content-list-task-workgroup .ant-table-thead > tr > th {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
}

.content-list-task-workgroup .ant-table-tbody {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}

.content-list-task-workgroup .ant-select-selection-search {
  font-size: 16px;
}

// hidden brand text of tinymce
.tox-statusbar__branding {
  display: none !important;
}

.view-content-workgroup {
  .ant-input {
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  input {
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
}

.ant-picker-input input {
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.view-content-category-list .ant-table-thead > tr > th {
  color: #000000;
  font-size: 16px;
  font-weight: bold;
}

.view-content-category-list .ant-table-tbody {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}

.border_error {
  border: 1px solid red;
}

.tox-notifications-container {
  display: none !important;
}

@media screen and (min-width: 768px) {
  .custom-selected-multi-more {
    .ant-select-selection-overflow-item {
      max-width: 100px;
    }
  }
  .custom-selection-work-list-filter {
    .ant-select-selection-overflow-item {
      max-width: 100px;
    }
  }
  .custom-selected-multi-workgroup {
    .ant-select-selection-overflow-item {
      max-width: 350px;
    }
  }
  .custom-selected-multi-workgroup-assign {
    .ant-select-selection-overflow-item {
      max-width: 250px;
    }
  }
  .custom-selected-multi-more-report {
    .ant-select-selection-overflow-item {
      max-width: 120px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .custom-selected-multi-more {
    .ant-select-selection-overflow-item {
      max-width: 100px;
    }
  }
  .custom-selection-work-list-filter {
    .ant-select-selection-overflow-item {
      max-width: 100px;
    }
  }
  .custom-selected-multi-workgroup {
    .ant-select-selection-overflow-item {
      max-width: 350px;
    }
  }
  .custom-selected-multi-workgroup-assign {
    .ant-select-selection-overflow-item {
      max-width: 250px;
    }
  }
  .custom-selected-multi-more-report {
    .ant-select-selection-overflow-item {
      max-width: 120px;
    }
  }
}

@media screen and (min-width: 1600px) {
  .custom-selected-multi-more {
    .ant-select-selection-overflow-item {
      max-width: 250px;
    }
  }
  .custom-selection-work-list-filter {
    .ant-select-selection-overflow-item {
      max-width: 150px;
    }
  }
  .custom-selected-multi-workgroup {
    .ant-select-selection-overflow-item {
      max-width: 350px;
    }
  }
  .custom-selected-multi-workgroup-assign {
    .ant-select-selection-overflow-item {
      max-width: 250px;
    }
  }
  .custom-selected-multi-more-report {
    .ant-select-selection-overflow-item {
      max-width: 250px;
    }
  }
}

.custom-datepicker-month .ant-picker-cell-inner {
  min-height: 48px;
}

.react-select__control {
  height: 200px !important;
  overflow-y: scroll;
  align-items: flex-start !important;
}
.react-select__value-container {
  padding: 0px !important;
}
.view-select-month
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  display: flex;
  border: 1px solid #ededed !important;
  align-items: center;
  border-radius: 0.375rem !important;
  height: 2.875rem !important;
}
.custom-selection-work-list-filter .ant-select-selector {
  min-height: 46px !important;
  display: flex;
  align-items: center;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  min-height: 46px !important;
  display: flex;
  align-items: center;
}

.view-select-month
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  display: flex;
  border: 1px solid #ededed !important;
  align-items: center;
  border-radius: 0.375rem !important;
  height: 2.875rem !important;
}

.react-select__control {
  height: 200px;
  align-items: flex-start !important;
  overflow-y: auto;

  @media screen and (max-width: 1500px) {
    height: unset;
  }
}
.react-select__value-container {
  padding: 0;
  .react-select__multi-value {
    background: #fafafb;
    border: 1px solid #ededed;
    border-radius: 4px;
    color: #000000 !important;
    .react-select__multi-value__label {
    }
  }
  .react-select__input-container {
    margin: 0;
    padding: 0;

    .react-select__input {
      height: 30px;
    }
  }
}

.custom-selection-work-list-filter {
  .ant-select-selector {
    .ant-select-selection-overflow {
      .ant-select-selection-overflow-item {
        max-width: 350px;
        .ant-select-selection-item {
          background-color: #f5eace;
          border-radius: 0.375rem;
        }
      }
    }
  }
}

.custom-selection-work-list-filter {
  .activated {
    background: #f5eace;
    opacity: 0.8;
  }
  .ant-select-item-option-selected {
    background: #f5eace;
  }
  .ant-form-item-label {
    label {
      color: var(--text-colors-text-heading-dark, #000);
      /* Paragraphs & Texts/Nomal - Medium */
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 136%;
    }
  }
}

.my-custom-table {
  .ant-table-body::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 0.1875rem;
    background: transparent;
  }
  .ant-table-body {
    min-height: calc(100vh - 360px);
  }
  .ant-table-body::-webkit-scrollbar-thumb {
    background: #d9d9d9;
  }
  .ant-table-body::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .ant-table-container table > thead > tr > th {
    border-bottom: 1px solid var(--neutral-colors-neutral-smoke, #ededed);
    background: var(--neutral-colors-neutral-smoke, #ededed);
    color: var(--text-colors-text-heading-dark, #000);
    font-size: 0.875rem;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
  }

  td {
    color: var(--text-colors-text-heading-dark, #000);
    font-size: 0.875rem;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    line-height: 136%;
  }
  .ant-table-cell::before {
    display: none;
  }
}

.hidden-element {
  display: none !important;
}

.ql-disabled-container {
  .quill,
  svg,
  button,
  span,
  .ql-editor,
  p,
  image {
    cursor: not-allowed !important;
  }
}

.my-custom-table-qticket {
  .ant-table-body::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 0.1875rem;
    background: transparent;
  }
  .ant-table-body {
    min-height: calc(100vh - 650px);
  }
  .ant-table-body::-webkit-scrollbar-thumb {
    background: #d9d9d9;
  }
  .ant-table-body::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .ant-table-container table > thead > tr > th {
    border-bottom: 1px solid var(--neutral-colors-neutral-smoke, #ededed);
    background: var(--neutral-colors-neutral-smoke, #ededed);
    color: var(--text-colors-text-heading-dark, #000);
    font-size: 0.875rem;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
  }

  td {
    color: var(--text-colors-text-heading-dark, #000);
    font-size: 0.875rem;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    line-height: 136%;
  }
  .ant-table-cell::before {
    display: none;
  }
}
.qticket-hardware {
  .ant-switch-checked {
    background: #f0b71c;
  }
}

.disabledBtn {
  cursor: not-allowed !important;
  opacity: 0.6;
}
.custom-hot-toast-component__container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 6px;
  margin: 8px 6px;
  max-height: calc(100vh - 50px);

  .success-container {
    display: flex;
    align-items: center;
    gap: 10px;
    border: solid 0.5px #19d819;
    border-radius: 6px;
    padding: 12px;
  }
  .error-container {
    display: flex;
    align-items: center;
    flex: 1;
    min-height: 0;
    gap: 10px;
    border: solid 0.5px #ff41419a;
    border-radius: 6px;
    padding: 12px;

    .error-message {
      height: 100%;
      overflow-y: auto;
    }
  }
}

.hidden-element {
  display: none !important;
}

.hidden-element {
  display: none !important;
}

.text-overflow-ellipsis {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.qticket-hardware {
  .ant-switch-checked {
    background: #f0b71c;
  }
}

.disabledBtn {
  cursor: not-allowed !important;
  opacity: 0.6;
}

.custom-hot-toast-component__container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 6px;
  margin: 8px 6px;
  max-height: calc(100vh - 50px);

  .success-container {
    display: flex;
    align-items: center;
    gap: 10px;
    border: solid 0.5px #19d819;
    border-radius: 6px;
    padding: 12px;
  }
  .error-container {
    display: flex;
    align-items: center;
    flex: 1;
    min-height: 0;
    gap: 10px;
    border: solid 0.5px #ff41419a;
    border-radius: 6px;
    padding: 12px;

    .error-message {
      height: 100%;
      overflow-y: auto;
    }
  }
}
.z-index-100 {
  z-index: 100;
}

.container-full-flex-center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-text {
  font-family: var(--font-family);
  font-size: 14px;
  color: rgb(255, 77, 79);
  line-height: 22.001px;
  font-weight: 400;
  font-feature-settings: "tnum";
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: tabular-nums;
  font-variant-position: normal;
  height: 21.9792px;
  list-style-image: none;
  list-style-position: outside;
}

.short-editor {
  .ql-editor {
    min-height: 100px !important;
    font-family: var(--font-family-regular) !important;
  }
}

.quill-err {
  border: 1px solid #ff4d4f;
  border-radius: 6px;

  .ql-toolbar {
    &.ql-snow {
      // border: 0;
    }
  }
  .ql-container {
    margin-bottom: 0;
  }
}

.short-container {
  max-height: 250px !important;
}

//Custom nút radio background vàng / select màu đen
.work-groups-custom-radio {
  .ant-radio-checked {
    .ant-radio-inner {
      background: var(--primary-color);
      border: 0;
      outline: 0;
      &::after {
        background: black;
      }
    }
  }
}

.select-custom {
  .ant-select-item.ant-select-item-option {
    padding: 0;
  }
  .ant-select-item-option-state {
    padding-top: 5px;
    padding-right: 12px;
  }
  .demo-option-label-item {
    padding: 5px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .option-check-icon {
      width: 14px;
      height: 14px;
      display: none;
    }
    &.active {
      background-color: #fff9e8;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
      .option-check-icon {
        display: block;
      }
    }
  }
}
