.wrapper {
  width: 100%;
  height: 100vh;
  background-color: #e9ecef;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.link {
  text-decoration: none;
  &:hover {
    color: var(--primary-color);
  }
  transition: all 0.2s;
}

.formContent {
  width: 400px;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  text-align: center;
}
@media screen and (min-width: 500px) {
  .formContent {
    padding: 2rem;
  }
}

.footer {
  padding: 1rem;
  text-align: center;
  color: #6e707e;
}

.logo {
  width: 60px;
  height: auto;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.link {
  color: var(--text-color);
}
