.empty-data {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  background-color: white;
  padding-bottom: 30px;;
     
  .total {
    height: 46px;
    width: 100%;
    background: var(--primary-colors-brand-brand-primary-dark-green, #17345b);
    color: white;
    display: flex;
    align-items: center;
    padding-left: 12px;
  }
}
