.system-management-wrapper {
  .ant-select-selector {
    height: 46px !important;
    display: flex;
    align-items: center;
  }

  .ant-select-disabled {
    .ant-select-selector {
      background: #ededed !important;
      border: #ededed !important;
    }
  }

  .ant-select-selection-placeholder {
    font-family: "GoogleSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 162.02%;
  }

  .ant-select-show-search {
    .ant-select-selection-search-input {
      font-family: "GoogleSans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 162.02%;
      min-height: 46px;
    }

    .ant-select-selection-item {
      font-size: 16px;
    }
  }

  .ant-form-item-label > label {
    font-weight: 500;
    font-size: 16px;
    line-height: 136%;
    color: #434243;
  }

  .ant-input {
    font-family: "GoogleSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 162.02%;
    padding: 10px 20px;
    height: 46px;
  }

  .ant-radio-wrapper {
    font-family: "GoogleSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 162.02%;
    color: #7a7a7a;

    .ant-radio-checked .ant-radio-inner {
      border-color: transparent;
      background: #f0b71c;
    }

    .ant-radio-inner::after {
      background: #434243;
    }
  }

  .ant-form-item {
    margin-bottom: 18px;

    .ant-form-item-label > label {
      font-family: "GoogleSans";
      font-weight: 500;
      font-size: 16px;
      line-height: 136%;
    }
  }

  .ant-switch-checked {
    background: #f0b71c;
  }

  .ant-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    width: 160px;
    height: 45px;
    background: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    font-family: "GoogleSans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #000000;

    &.ant-btn-primary {
      background: #f0b71c;
      border: 1px solid #f0b71c;
    }

    &.ant-btn-icon-only {
      width: 46px;
      padding: 14px;
      height: 46px;
    }
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    svg path {
      stroke: #d9d9d9;
    }
  }

  .ant-modal-header {
    border-bottom: 0;
    border-radius: 6px 6px 0px 0px;
    padding: 18px 16px;
    gap: 10px;
    background: #fafafb;

    .ant-modal-title {
      font-family: "GoogleSans";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 136.52%;
    }
  }

  &.ant-modal-confirm {
    .ant-modal-body {
      padding: 32px 10px;
    }

    .ant-modal-confirm-content {
      text-align: center;
      font-family: "GoogleSans";
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      color: #000000;
      margin-top: 0;

      svg {
        width: 40px;
        height: 40px;
        cursor: default;
      }

      p {
        margin-top: 10px;
        font-weight: 400;
        font-size: 20px;
        line-height: 136.02%;
      }
    }

    .ant-modal-confirm-btns {
      display: flex;
      justify-content: center;
      margin-top: 22px;

      .ant-btn {
        width: 200px;
      }
    }
  }

  .kpi-select-dropdown {
    > svg path {
      stroke: #f0b71c;
      fill: #f0b71c;
    }
  }

  &.ant-notification-notice {
    padding: 0 20px;
    border-radius: 6px;
    width: auto;

    .ant-notification-notice-with-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 220px;
      min-height: 55px;

      svg {
        width: 32px;
        height: 32px;
        cursor: default;
      }

      .ant-notification-notice-icon {
        position: relative;
      }

      .ant-notification-notice-message {
        font-size: 16px;
        margin-left: 6px;
        margin-bottom: unset;
      }

      .ant-notification-notice-description {
        margin-left: unset;
      }
    }

    .ant-notification-notice-close {
      display: none;
    }
  }

  .kpi-input-search.ant-input-group-wrapper {
    .ant-input {
      border-right: 0;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }

    button {
      border-left: 0;
      border-bottom-right-radius: 6px !important;
      border-top-right-radius: 6px !important;
    }

    .ant-input-group-addon {
      background: transparent;
      border-bottom-right-radius: 6px !important;
      border-top-right-radius: 6px !important;
    }
  }

  .ant-modal-close {
    top: 10px;
  }

  .min-height-268 {
    min-height: 268px;
    height: calc(100vh - 480px);
  }

  .kpi-table {
    tr {
      font-family: "GoogleSans";
      font-style: normal;
      font-size: 16px !important;

      td,
      th {
        padding: 16px !important;
        min-height: 52px !important;
        height: unset !important;
      }

      &:hover {
        background: #fafafb;
      }
    }
  }

  .kpi-table-antd {
    th {
      font-family: "GoogleSans";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      background: #ffffff;
      padding: 18px 10px;

      &::before {
        content: unset !important;
      }
    }

    td {
      font-family: "GoogleSans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 162.02%;
      background: #ffffff;
      padding: 18px 10px;
    }

    tr.ant-table-row-selected > td {
      background: transparent;
    }
  }

  .ant-checkbox:hover::after,
  .ant-checkbox-wrapper:hover .ant-checkbox::after,
  .ant-checkbox-checked::after {
    border-color: #bcb3e2;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #bcb3e2;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #434243;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f0b71c;
    border-color: #f0b71c;

    &::after {
      border-color: #434243;
    }
  }

  .text-center {
    text-align: center;
  }

  .ant-input:focus,
  .ant-input-focused,
  .ant-input:hover,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #d9d9d9;
    box-shadow: unset;
  }

  .ant-picker {
    width: 100%;
    height: 46px;
    padding: 10px 16px 10px 20px !important;
  }

  .ant-picker-focused {
    border-color: #d9d9d9 !important;
    box-shadow: unset;
  }

  .ant-picker:hover {
    border-color: #d9d9d9 !important;
  }

  .ant-upload-list-item {
    margin-top: 10px;
    height: 46px !important;
    padding-right: 10px;
  }

  .ant-select-clear {
    font-size: 14px;
    width: 14px;
    height: 14px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 20px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 20px;
  }

  .ant-select-multiple {
    .ant-select-selector {
      height: unset !important;
      min-height: 46px;
      padding: 0 30px 0 20px !important;
    }
    .ant-select-selection-search {
      margin-inline-start: unset;
    }
  }
  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
    input {
      min-height: 22px;
    }
  }
  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
    height: 22px;
  }
  .ant-select-selection-placeholder {
    padding: 0 10px;
  }

  .ant-input[disabled] {
    border-color: #ededed;
    background: #ededed;
  }

  .ant-radio {
    &:hover {
      .ant-radio-inner {
        border-color: #f0b71c;
      }
    }
    .ant-radio-inner {
      box-shadow: none;
    }
  }

  .ant-form-item-explain-error {
    color: var(--text-danger);
    font-size: 0.75rem;
    margin-top: 5px;
    line-height: 20px;
    padding-left: 18px;
    margin-bottom: 16px;
    position: relative;
  }

  .ant-form-item-explain-error::before {
    content: "";
    position: absolute;
    width: 3px;
    height: 3px;
    border-radius: 3px;
    background-color: var(--text-danger);
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: var(--text-danger) !important;
  }

  .ant-form-item-control-input {
    min-height: unset;
  }

  .ant-select-multiple .ant-select-selector {
    max-height: 170px;
    overflow-y: auto;
  }
  .ant-select-multiple .ant-select-selection-item {
    background-color: #f5eace;
    border-radius: 4px;
    border-color: #f5eace;
  }

  .ant-select-multiple .ant-select-selection-overflow-item {
    margin-right: 4px;
  }
  .ant-select-multiple .ant-select-selection-overflow {
    margin: 6px 0;
  }
}
