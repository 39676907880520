.file {
    height: 46px;
    width: 100%;
    display: flex;
    align-items: center;
    .button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        gap: 10px;
    
        width: 108px;
        height: 46px;
    
        background: #F0B71C;
        border-radius: 6px;
        flex: none;
        order: 0;
        flex-grow: 0;
        cursor: pointer;
    }
    
    .totle {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-decoration-line: underline;
        color: #F0B71C;
        flex: none;
        order: 0;
        flex-grow: 0;
        height: 26px;
        padding-left: 30px;
        cursor: pointer;
    }
    .icon{
        height: 20px;
        width: 20px;
        line-height: 20px;
    }
    .icon svg{
        width: 20px;
        cursor: pointer;
    }
}

