.datepicker-container {
  display: flex;
  border: 1px solid #ededed;
  width: 100%;
  align-items: center;
  padding: 0 1rem;
  border-radius: 0.375rem;
  height: 46px !important;
}

.datepicker-container input {
  outline: none;
  border: none !important;
  font-size: 1rem;
  font-family: inherit;
  width: 100%;
  height: 43px !important;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container svg {
  width: 1.7rem;
}

.disable {
  background-color: #0000000f;
}
.clear::after {
  background: #eaeaea !important;
  color: black;
}
