.datepicker-container {
  display: flex;
  border: 1px solid #ededed;
  background: #ffffff;
  align-items: center;
  padding: 10px 12px;
  border-radius: 0.375rem;
  height: 2.875rem;
  input {
    outline: none;
    border: none;
    font-size: 1rem;
    font-family: inherit;
    width: 100%;
    background: inherit;
  }
}

.icon-container {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 1.7rem;
    cursor: pointer;
  }
}

.react-datepicker__navigation .datepicker-disabled,
.datepicker-disabled {
  background: hsl(0, 0%, 95%);
}

.error {
  border-color: var(--text-danger);
}
