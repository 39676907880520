.wrapper {
  width: 100%;
  height: 51.8rem;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    font-size: 1.25rem;
    margin-top: 1.5rem;
    font-weight: 400;
  }
}
