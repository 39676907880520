.file {
  display: none;
}
.ui_uploadfile {
  button {
    background: var(--primary-color) !important;
  }
}
.uploadfile {
  button {
    background-color: white;
    border: none;
  }
}

