@mixin regular {
  font-family: var(--font-family-regular) !important;
  font-weight: 400 !important;
}

@mixin bold {
  font-family: var(--font-family-medium);
  font-weight: 500;
}

@mixin bolder {
  font-family: var(--font-family-bold);
  font-weight: 700;
}
