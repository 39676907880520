.tree-performance {
  :global .ant-select-selection-overflow-item {
    max-width: 120px  !important
  }

 
}
.tag-number{
  :global .ant-select-selection-overflow-item {
    max-width: calc(100% - 85px) !important;
  }
}
.tag-number-1 {
    :global .ant-select-selection-overflow-item {
      max-width: 90% !important;
    }
  }
  
  .tag-number-2 {
    :global .ant-select-selection-overflow {
      width: 90% !important
    }
    :global
      .ant-select-selection-overflow-item:not(
        .ant-select-selection-overflow-item-suffix
      ):not(.ant-select-selection-overflow-item-rest) {
      max-width: 50% !important;
    }
  }